import React, { useContext } from "react";
import AppContext from "../../context";
import styles from "./Vouchers.module.scss";

const Vouchers = () => {
  const { priceList } = useContext(AppContext);
  return (
    <main>
      <h2>VOUCHERY</h2>
      <p className={styles.voucherCont}>
        &nbsp;&nbsp;Podaruj bliskiej Ci osobie prezent i zapewnij jej
        niezapomnianą przygodę z makijażem.
        <br />
        <br />
        Voucher do wykorzystania przez okres 1 roku.
        <br />
        <br />
        Cena od{" "}
        {priceList.map((item) => (
          <strong>{item.voucherPrice}</strong>
        ))}
      </p>
    </main>
  );
};

export default Vouchers;
