import React, { useContext } from "react";
import AppContext from "../../context";
import styles from "./Contact.module.scss";
import {
  faInstagramSquare,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactForm from "../../components/contactForm/ContactForm";
import ContactList from "../../components/contactList/ContactList";

const Contact = () => {
  const { contact } = useContext(AppContext);
  return (
    <main>
      <h2>KONTAKT</h2>
      <div className={styles.contactCont}>
        <ContactList />
        <div className={styles.contactContainerIcons}>
          <a
            href="https://www.instagram.com/turczynska_gocko_makeup/"
            target="_blank"
          >
            <p>
              <FontAwesomeIcon icon={faInstagramSquare}>
                {" "}
                turczynska_gocko_makeup{" "}
              </FontAwesomeIcon>
              &nbsp;&nbsp;&nbsp;<strong> turczynska_gocko_makeup</strong>
            </p>
          </a>
          <a
            href="https://www.facebook.com/pages/category/Product-Service/Turczy%C5%84ska-Gocko-Makeup-100720914655675/"
            target="_blank"
          >
            <p>
              <FontAwesomeIcon icon={faFacebookSquare}>
                {" "}
                Turczyńska-Gocko Makeup{" "}
              </FontAwesomeIcon>
              &nbsp;&nbsp;&nbsp;<strong>Turczyńska-Gocko Makeup</strong>
            </p>
          </a>
        </div>
      </div>

      <main className={styles.googleMapContainer}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2525.4579254762043!2d16.651701!3d50.7299951!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470e360ff6b755b3%3A0x7f9a0bb8c71c12b0!2sKlasztorna%2022%2C%2058-200%20Dzier%C5%BConi%C3%B3w!5e0!3m2!1spl!2spl!4v1663280370874!5m2!1spl!2spl"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </main>
      <ContactForm />
    </main>
  );
};

export default Contact;
