import React, { useContext } from "react";
import AppContext from "../../context";
import styles from "./ContactList.module.scss";

import { faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactList = ({}) => {
  const { contact } = useContext(AppContext);

  return (
    <>
      <ul styles={styles.contactUl}>
        {contact.map((item) => (
          <>
            <p>
              <strong>{item.contactTitle}</strong>
            </p>
            <p>
              <strong>{item.contactStreet}</strong>
            </p>
            <p>
              <strong>{item.contactCity}, woj. Dolnośląskie</strong>
            </p>
            <p className={styles.red}>
              <FontAwesomeIcon icon={faPhoneSquare}>
                695 821 482
              </FontAwesomeIcon>
              &nbsp;&nbsp;&nbsp;<strong>{item.contactNumber}</strong>{" "}
            </p>
          </>
        ))}
      </ul>
    </>
  );
};

export default ContactList;
