import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { Button, Checkbox, TextField } from "@material-ui/core";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import styles from "./ContactForm.module.scss";
import { routes } from "../../routes/index";
import { Link } from "react-router-dom";

const contactFormValidationSchema = Yup.object().shape({
  nameAndSurname: Yup.string()
    .required("Wpisz imię i nazwisko")
    .min(3, "Za krótkie imię"),
  email: Yup.string()
    .required("Wpisz email do korespondescji")
    .email("niepoprawny email"),
  message: Yup.string()
    .required("Wpisz treść wiadomości")
    .min(7, "Wiadomość za krótka"),
  acceptTerms: Yup.bool().oneOf(
    [true],
    "Aby wysłać wiadomość zaakceptuj warunki"
  ),
});

const ContactForm = () => {
  return (
    <Formik
      initialValues={{
        nameAndSurname: "",
        email: "",
        message: "",
        acceptTerms: false,
      }}
      validationSchema={contactFormValidationSchema}
      onSubmit={(values, { resetForm }) => {
        emailjs
          .send(
            process.env.REACT_APP_SERVICE,
            process.env.REACT_APP_TEMPLATE,
            values,
            process.env.REACT_APP_USER
          )
          .then(() => alert("Wiadomość została wysłana"))
          .catch((err) => console.log(err));

        resetForm();
      }}
    >
      {({ values, handleChange }) => (
        <Form>
          <h1 className={styles.formTitle}>Napisz do mnie!</h1>
          <p>Chętnie odpowiem na wszytkie pytania. Zapraszam do kontaktu!</p>
          <TextField
            style={{ margin: "20px" }}
            name="nameAndSurname"
            label="Imię i nazwisko"
            value={values.nameAndSurname}
            onChange={handleChange}
            variant="outlined"
          />
          <div className={styles.formError}>
            {" "}
            <ErrorMessage name="nameAndSurname" />
          </div>

          <TextField
            style={{ margin: "20px" }}
            name="email"
            label="Email"
            variant="outlined"
            value={values.email}
            onChange={handleChange}
          />
          <div className={styles.formError}>
            {" "}
            <ErrorMessage name="email" />
          </div>
          <TextField
            style={{ margin: "20px" }}
            name="message"
            label="Wiadomość"
            variant="outlined"
            multiline={true}
            value={values.message}
            onChange={handleChange}
          />
          <div className={styles.formError}>
            {" "}
            <ErrorMessage name="message" />
          </div>
          <div className={styles.checkboxContainer} style={{ margin: "20px" }}>
            <label for="acceptTerms">
              <Checkbox
                name="acceptTerms"
                checked={values.acceptTerms}
                onChange={handleChange}
              />
              Zaakcetuj Politykę Prywatności
            </label>
            <div className={styles.formError}>
              {" "}
              <ErrorMessage name="acceptTerms" />
            </div>
          </div>
          <Button
            className={styles.acceptButton}
            type="submit"
            variant="outlined"
          >
            Wyślij
          </Button>
          <Link to={routes.privacyPolicy}>
            <p className={styles.privacyPolicy}>
              ZAPOZNAJ SIE Z POLITYKĄ PRYWATNOŚCI
            </p>
          </Link>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
