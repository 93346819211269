import React, { useContext } from "react";
import MyWorksList from "../../components/MyWorksList/MyWorksList";

const MyWorks = () => {
  return (
    <main>
      <h2>GALERIA</h2>
      <MyWorksList />
    </main>
  );
};

export default MyWorks;
