import React, { useState, useEffect } from "react";
import Router from "./routing/Router";
import AppContext from "./context";
import { client } from "./contentful/config";

const App = () => {
  const [myWorks, setMyWorks] = useState([]);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectImage, setSelectImage] = useState("");
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [contact, setContactContent] = useState([]);
  const [priceList, setPriceListContent] = useState([]);

  const handleImageModalOpen = (image) => {
    setSelectImage(image);
    setImageModalOpen(true);
  };

  const handleImageModalClose = () => {
    setImageModalOpen(false);
  };

  const setMyWorksData = (data) => {
    const formattedMyWorks = data.map((item) => {
      const workId = item.sys.id;
      const image = item.fields.image.fields.file.url;
      const workTitle = item.fields.imageTitle;

      return {
        workId,
        image,
        workTitle,
      };
    });

    setMyWorks([...formattedMyWorks]);
  };

  const setContactData = (data) => {
    console.log("DATA", data);
    const formattedContact = data.map((item) => {
      const contactTitle = item.fields.contactTitle;
      const contactStreet = item.fields.contactStreet;
      const contactCity = item.fields.contactCity;
      const contactNumber = item.fields.contactNumber;
      console.log("CONTACTTITLE", contactTitle);
      console.log("CONTACTstreet", contactStreet);
      console.log("contactCity", contactCity);
      console.log("contactNumber", contactNumber);

      return {
        contactTitle,
        contactStreet,
        contactCity,
        contactNumber,
      };
    });

    setContactContent([...formattedContact]);
  };

  const setPriceListData = (data) => {
    console.log("PRICELISTDATA", data);

    const formattedPriceList = data.map((item) => {
      const voucherPrice = item.fields.voucherPrice;
      const indMakeupPrice = item.fields.indMakeupPrice;
      const VIPWeddPackage = item.fields.VIPWeddPackage;
      //const WeddMakeStudio = item.fields.WeddMakeStudio;
      const trainings = item.fields.trainings;
      const sprayTanning = item.fields.sprayTanning;
      const priceKm = item.fields.priceKm;

      console.log("voucherPrice", voucherPrice);
      console.log("indMakeupPrice", indMakeupPrice);
      console.log("VIPWeddPackage", VIPWeddPackage);
      //console.log("WeddMakeStudio", WeddMakeStudio);
      console.log("trainings", trainings);
      console.log("sprayTanning", sprayTanning);
      console.log("priceKm", priceKm);

      return {
        voucherPrice,
        indMakeupPrice,
        VIPWeddPackage,
        //WeddMakeStudio,
        trainings,
        sprayTanning,
        priceKm,
      };
    });
    setPriceListContent([...formattedPriceList]);
  };

  const getMyWorksData = () => {
    client
      .getEntries({
        content_type: "galleryImage",
      })
      .then((res) => {
        setMyWorksData(res.items);
      })
      .catch((err) => console.log(err));
  };

  const getContactData = () => {
    client
      .getEntries({
        content_type: "kontakt",
      })
      .then((res) => {
        setContactData(res.items);
      })
      .catch((err) => console.log(err));
  };

  const getPriceListData = () => {
    client
      .getEntries({
        content_type: "priceList",
      })
      .then((res) => {
        setPriceListData(res.items);
      })
      .catch((err) => console.log(err));
  };

  const onClickOpenMenu = () => {
    setMenuOpen(true);
  };
  const onClickCloseMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    getMyWorksData();
  }, []);

  useEffect(() => {
    getContactData();
  }, []);

  useEffect(() => {
    getPriceListData();
  }, []);

  return (
    <AppContext.Provider
      value={{
        myWorks,
        contact,
        imageModalOpen,
        selectImage,
        handleImageModalClose,
        handleImageModalOpen,
        isMenuOpen,
        onClickOpenMenu,
        onClickCloseMenu,
        priceList,
      }}
    >
      <Router />
    </AppContext.Provider>
  );
};

export default App;
